import React from "react";
import { Link } from "gatsby";
import team1 from "../assets/images/team-1-1.jpg";
import team2 from "../assets/images/team-1-2.jpg";
import team3 from "../assets/images/team-1-3.jpg";
import team4 from "../assets/images/team-1-4.jpg";
import team5 from "../assets/images/team-1-5.jpg";
import team6 from "../assets/images/team-1-6.jpg";
import team7 from "../assets/images/team-1-7.jpg";
import team8 from "../assets/images/team-1-8.jpg";

const Teachers = () => {
  return (
    <section className="team-one team-page">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
                <img src={team1} alt="" />
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <Link to="/teachers/neeti">Neeti Nagarkar</Link>
                </h2>
                <p className="team-one__designation">Teacher</p>
                <p className="team-one__text">
                  There are many varia of passages of lorem.
                </p>
              </div>
              <div className="team-one__social">
                <a href="#none">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-facebook-square"></i>
                </a>

                <a href="#none">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
                <img src={team2} alt="" />
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <Link to="/teachers/anagha">Ms Anagha Bahulikar</Link>
                </h2>
                <p className="team-one__designation">Teacher</p>
                <p className="team-one__text">
                  There are many varia of passages of lorem.
                </p>
              </div>
              <div className="team-one__social">
                <a href="#none">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-facebook-square"></i>
                </a>

                <a href="#none">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
                <img src={team3} alt="" />
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <Link to="/teachers/hiral">Hiral Shah</Link>
                </h2>
                <p className="team-one__designation">Teacher</p>
                <p className="team-one__text">
                  There are many varia of passages of lorem.
                </p>
              </div>
              <div className="team-one__social">
                <a href="#none">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-facebook-square"></i>
                </a>

                <a href="#none">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
            <div className="team-one__single">
              <div className="team-one__image">
                <img src={team4} alt="" />
              </div>
              <div className="team-one__content">
                <h2 className="team-one__name">
                  <Link to="/teachers/ruchi">Ruchi Shah</Link>
                </h2>
                <p className="team-one__designation">Teacher</p>
                <p className="team-one__text">
                  There are many varia of passages of lorem.
                </p>
              </div>
              <div className="team-one__social">
                <a href="#none">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-facebook-square"></i>
                </a>

                <a href="#none">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Teachers;
