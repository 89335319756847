import React from "react";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import Teachers from "../components/Teachers";


const TeachersPage = () => {
  return (
    <Layout pageTitle="KWEC | Team">
      <Navbar />
      <PageHeader title="Our Team" />
      <Teachers />
      <Footer />
    </Layout>
  );
};

export default TeachersPage;
